<template>
  <v-container
      id="extended-tables"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">
        <base-material-card
            color="success"
            icon="mdi-account-multiple"
            title="Users"
        >

          <v-row no-gutters>
            <v-col cols="12" md="12">

              <data-grid
                  :grid="grid"
                  @doAction="gridAction"
                  @paginate="listUsers"
              >
                <template v-slot:column-name="{ item }">
                  <div class="font-weight-medium">{{ item.firstName }} {{ item.lastName }}</div>
                  <span class="font-weight-light mt-1 font-italic grey--text">({{ item.username }})</span>
                </template>

                <template v-slot:column-email="{ item }">
                  <a class="blue--text" :href="`mailto:${item.email}`">{{ item.email }}</a>
                </template>

                <template v-slot:column-status="{ item }">
                  <v-chip small label :color="item.status === 'active' ? 'success' : 'warning'">
                    {{ item.status }}
                  </v-chip>
                </template>

              </data-grid>

            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
    <change-mfa ref="changeMfa"
                @refreshList="refreshList"
                :env-selected="envSelected"></change-mfa>
  </v-container>
</template>
<script>

import ChangeMfa from "./changeMfa";

import usersMixins from "@/views/internal/pages/organization/mixins";
import globalMixins from "../../../../mixins/globalMixins";
import dataGrid from "@/views/internal/components/custom/dataGrid";

const data = {
  grid: {
    //mandatory
    headers: [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        align: 'right',
        text: 'Actions',
        value: 'actions',
      },
    ],
    items: [],
    selected: [],
    //optional
    loading: true,
    //optional
    columns: [
      {
        name: 'item.name'
      },
      {
        name: 'item.email'
      },
      {
        name: 'item.status'
      }
    ],
    //optional
    search: {
      keyword: ''
    },
    //optional
    itemID: 'id',
    //optional
    multi: [
      {
        icon: 'delete',
        label: 'Delete',
        color: 'error',
        acl: {
          method: 'delete',
          route: `/organization/users/:id`
        },
        method: 'deleteUser',
        //optional
        confirm: `Are you sure you want to delete these User(s)?`
      }
    ],
    //optional
    sortingList: ['name', 'status'],
    //optional
    headActions: [
      {
        icon: 'refresh',
        label: '',
        color: 'purple',
        acl: {
          module: 'saas',
          method: 'get', route: `/organization/users`
        },
        method: 'listUsers'
      },
      {
        icon: 'plus',
        label: 'create',
        color: 'success',
        acl: {
          module: 'saas',
          method: 'put', route: `/organization/users`
        },
        method: 'addUser'
      }
    ],
    //optional
    rowActions: [
      {
        method: 'enableMfa',
        color: 'blue',
        icon: 'mdi-two-factor-authentication',
        // condition: {
        //   field: 'mfa',
        //   value: false
        // },
        allow: {
          module: 'saas',
          method: 'patch',
          route: '/organization/users/:id'
        },
        tooltip: "Update MFA"
      },
      // {
      //   method: 'disableMfa',
      //   color: 'orange',
      //   icon: 'mdi-two-factor-authentication',
      //   condition: {
      //     field: 'mfa',
      //     value: true
      //   },
      //   allow: {
      //     module: 'saas',
      //     method: 'patch',
      //     route: '/organization/users/:id'
      //   },
      //   tooltip: "Disable MFA"
      // },
      {
        method: 'enableDisableUser',
        color: 'error',
        icon: 'mdi-lightbulb-off',
        alternate: {
          key: 'status',
          color: 'success',
          icon: 'mdi-lightbulb-on'
        },
        allow: {
          module: 'saas',
          method: 'patch',
          route: '/organization/users/:id'
        },
        tooltip: "Enable / Disable User"
      },
      {
        method: 'resetPassword',
        color: 'secondary',
        icon: 'mdi-lock',
        allow: {
          module: 'saas',
          method: 'patch',
          route: '/organization/users/:id'
        },
        tooltip: "Reset Password"
      },
      {
        method: 'editUser',
        color: 'success',
        icon: 'mdi-pencil',
        allow: {
          module: 'saas',
          method: 'patch',
          route: '/organization/users/:id'
        },
        tooltip: "Edit User"
      },
      {
        method: 'deleteUser',
        color: 'error',
        icon: 'mdi-delete',
        allow: {
          module: 'saas',
          method: 'delete',
          route: '/organization/users/:id'
        },
        tooltip: "Delete User"
      },
    ]
  }
};

const methods = {

  async listUsers(page, limit, sort) {
    this.grid.loading = true;
    this.grid.items = [];

    if (page !== undefined) {
      this.grid.page = page;
    }
    if (limit !== undefined) {
      this.grid.limit = limit;
    }
    if (sort !== undefined) {
      this.grid.sort = sort;
    }

    //todo: enabled the below after the api is ready.
    let users = await this.getSendData({
      noLoading: true,
      url: `/saas/organization/users`,
      method: "get",
      params: {
        offset: (this.grid.page !== undefined) ? this.grid.page : 1,
        limit: (this.grid.limit !== undefined) ? this.grid.limit : 10,
        //todo: reenable after API is ready
        //sort: this.grid.sort
      }
    });

    this.grid.items = users.items || [];
    this.grid.total = users.total || 1;
    this.grid.perPage = this.grid.limit;
    this.grid.items.forEach((one) => {
      one.mfa = false;
      if (one.security && one.security.mfa) {
        one.mfa = one.security.mfa;
      }
    });

    this.grid.loading = false;
  },

  //grid action hook
  gridAction(action, item, extra) {
    if (item) {
      this[action](item, extra);
    } else {
      this[action](extra);
    }
  },

  addUser() {
    let limit = 2;
    if (process.env.VUE_LIMIT_USERS) {
      limit = process.env.VUE_LIMIT_USERS;
    }

    if (this.grid.total < limit) {
      this.goToPage({route: 'addUser'});
    } else {
      alert(`User limit (${limit}) exceeded!`);
    }
  },

  editUser(item) {
    this.goToPage({route: 'editUser', params: {id: item.id}});
  },

  deleteUser(item, multi) {
    const _self = this;
    if (multi || (!multi && confirm(`Are you sure you want to delete user: ${item.username}`))) {
      doDelete();
    }

    function doDelete() {
      const apiOptions = {
        url: `/saas/organization/users/${item.id}`,
        method: "delete"
      };
      _self.getSendData(apiOptions)
          .then(() => {
            _self.pushMessage({
              type: 'success',
              title: `User Deleted`,
              text: `This user has been deleted.`
            });
            setTimeout(() => {
              _self.listUsers();
            }, 1000);
          });
    }
  },

  enableDisableUser(item) {
    const _self = this;
    let status = (item.status === 'active') ? 'disabled' : 'active';
    if (confirm(`Are you sure you want to change the status of user: ${item.username} to ${status}`)) {
      doChangeStatus();
    }

    function doChangeStatus() {
      const apiOptions = {
        url: `/saas/organization/users/${item.id}`,
        method: "patch",
        "params": {
          "status": status
        }
      };

      _self.getSendData(apiOptions)
          .then(() => {
            _self.pushMessage({
              type: 'success',
              title: `User Status Updated`,
              text: `The status of this user has been updated.`
            });
            setTimeout(() => {
              _self.listUsers();
            }, 2000);
          });
    }
  },

  resetPassword(item) {
    this.changeUserPassword(item);
  },

  doChangeMfa(apiOptions) {
    const _self = this;
    _self.getSendData(apiOptions)
        .then(() => {
          _self.pushMessage({
            type: 'success',
            title: `User Updated`,
            text: `The user has been updated.`
          });
          setTimeout(() => {
            _self.listUsers();
          }, 2000);
        });
  },

  disableMfa(item) {
    const _self = this;
    let security = {};
    if (item.security) {
      security = item.security;
    }
    security.mfa = false;
    const apiOptions = {
      url: `/saas/organization/users/${item.id}`,
      method: "patch",
      "params": {
        "security": security
      }
    };
    if (confirm(`Are you sure you want to DISABLE MFA for user: ${item.username}`)) {
      _self.doChangeMfa(apiOptions);
    }
  },

  refreshList() {
    setTimeout(() => {
      this.listUsers();
    }, 2000);
  },

  enableMfa(item) {
    if (Object.hasOwnProperty.call(this.$refs.changeMfa, 'changeMfaConfig')) {
      this.$refs.changeMfa.changeMfaConfig(item);
    }
  },
};

export default {
  name: 'Users',
  methods: methods,
  data: () => (data),
  mixins: [globalMixins, usersMixins],
  props: {},
  components: {
    dataGrid, ChangeMfa
  }
}
</script>