<template>
  <custom-dialog :dialog="mfaDialog" :do-action="resetUserMfa">
    <v-row v-if="mfaDialog.data">
      <v-col cols="12">
        <v-skeleton-loader
            v-if="mfaDialog.loading"
            type="article, actions"
        ></v-skeleton-loader>
        <template v-else>
          <v-card>
            <v-card-title class="pl-5 ml-0 text-left">
              <p class="text-muted">Changing MFA for User '{{ mfaDialog.data.firstName }}
                {{ mfaDialog.data.lastName }}'
              </p>
            </v-card-title>

            <v-card-text>
              <v-select
                  label="MFA Security"
                  v-model="mfaDialog.data.myMfa"
                  required
                  item-text="text"
                  item-value="value"
                  :items="mfaSettings"
                  hint="Specify whether or not to turn on Multi Factor Authentication."
                  persistent-hint
              ></v-select>

            </v-card-text>
          </v-card>
        </template>
      </v-col>

    </v-row>
  </custom-dialog>
</template>
<script>

import globalMixins from "@/mixins/globalMixins";
import CustomDialog from "@/views/internal/components/custom/dialog";

export default {
  name: 'ChangeMFA',

  data() {
    return {
      mfaDialog: {
        title: 'Change User MFA',
        trigger: false,
        loading: false,
        data: null,
        actions: [
          {
            method: 'resetUserMfa',
            icon: 'content-save',
            color: 'success',
            label: 'Change'
          }
        ]
      }
    }
  },

  props: {

  },

  components: {
    CustomDialog
  },

  mixins: [globalMixins],

  methods: {

    changeMfaConfig: function (user) {
      const _self = this;
      _self.mfaDialog.trigger = true;
      _self.mfaDialog.loading = true;
      _self.mfaDialog.data = JSON.parse(JSON.stringify(user));

      if (user.security && user.security.hasOwnProperty('mfa')) {
        _self.mfaDialog.data.myMfa = user.security.mfa;
      }

      _self.mfaDialog.data.originalMfa = this._lodash.clone(_self.mfaDialog.data.myMfa);

      setTimeout(() => {
        _self.mfaDialog.loading = false;
      }, 1000);
    },

    resetUserMfa: function (data) {
      const _self = this;
      this.mfaDialog.width = 500;
      this.mfaDialog.errors = [];

      if (confirm(`Are you sure you want to update MFA for user: ${_self.mfaDialog.data.username}?`)) {
        doChange();
      }

      function doChange() {
        const apiOptions = {
          url: `/consoleapi/organization/users/${_self.mfaDialog.data.id}`,
          method: "patch",
          "params": {
            "mfa": _self.mfaDialog.data.myMfa
          }
        };
        // close the dialog
        _self.mfaDialog.trigger = false;
        _self.getSendData(apiOptions)
            .then(() => {
              _self.pushMessage({
                type: 'success',
                title: `User Updated`,
                text: `The MFA of user ${_self.mfaDialog.data.firstName} ${_self.mfaDialog.data.lastName} has been changed.`
              });
              _self.$emit('refreshList', 'done');
            });
      }
    }
  }
};
</script>