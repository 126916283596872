<template>
  <v-dialog
      v-model="dialog.trigger"
      :persistent="dialog.persistent || true"
      :max-width="(dialog.width || 600) + 'px'"
  >
    <v-card>
      <v-card-title>
        <span class="headline text-capitalize">{{ dialog.title }}</span>
      </v-card-title>
      <v-card-text>
        <slot/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <template v-for="(dialogAction, i) in dialog.actions">
          <v-btn
              :key="i"
              v-if="!dialogAction.allow || canAccess(dialogAction.allow)"
              :color="dialogAction.color"
              small
              @click="doAction(dialogAction.method, $event)"
              class="mr-1"
          >
            <v-icon
                v-if="dialogAction.icon"
                small
                :class="(dialogAction.label && dialogAction.label.trim() !=='') ? 'mr-1': ''"
            >mdi-{{ dialogAction.icon }}
            </v-icon>
            {{ dialogAction.label }}
          </v-btn>
        </template>
        <v-btn
            color="error"
            small
            @click="dialog.trigger = false"
        >
          <v-icon
              small
              class="mr-1"
          >mdi-close
          </v-icon>
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import globalMixins from "@/mixins/globalMixins";

export default {
  name: 'CustomDialog',

  mixins: [globalMixins],

  props: {
    envSelected: {
      type: Object
    },
    dialog: {
      type: Object
    },
    doAction: {
      type: Function
    }
  }
}
</script>